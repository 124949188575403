<template>
  <div class="row" v-if="Object.keys(requisition).length !== 0">
    <requisition-hub-detail :requisition="requisition"></requisition-hub-detail>
    <requisition-header-detail :requisition="requisition" :spinning="spinning" :delayTime="delayTime"></requisition-header-detail>
    <requisition-header-status :current="current" :requisition="requisition"></requisition-header-status>
    <div class="col-md-12" id="div_reload">
      <div class="card">
        <div class="card-body">
          <form action="">
            <table class="table table-hover table-bordered">
              <thead>
              <tr>
                <th style="text-align: center;">Name</th>
                <th style="text-align: center;">Unit</th>
                <th style="text-align: center;">W. Stock</th>
                <th style="text-align: center;">Hub Stock</th>
                <th style="text-align: center;">Status</th>
                <th style="text-align: center;">Req Comment</th>
                <th style="text-align: center;">Req Qty</th>
                <th style="text-align: center;">Accepted</th>
                <th style="text-align: center;">Comment</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(product, index) in products" :key="index">
                <td>{{ product.name }}</td>
                <td>{{ product.unit }}</td>
                <td>{{ product.available_quantity || 0 }}</td>
                <td>{{ product.stock_amount || 0 }}</td>
                <td>
                  <span class="badge badge-warning" v-if="product.status === 'Pending'">Pending</span>
                  <span class="badge badge-danger" v-if="product.status === 'Rejected'">Rejected</span>
                  <span class="badge badge-success" v-if="product.status === 'Accepted'">Accepted</span>
                  <span class="badge badge-warning" v-if="product.status === 'Partial'">Partial</span>
                </td>
                <td>{{ product.req_comment }}</td>
                <td>{{ product.amount }}</td>
                <td>
                  <input type="number" class="form-control form-control-sm"  :id="'accepted_amount_'+product.id" v-model="product.accepted_amount" v-validate="{required: true, max: 6, regex: /^[0-9]*$/ }" @change="acceptQuantity($event.target.value, index)"  :name="'accepted_amount_' + index">
                </td>
                <td>
                  <input type="text" class="form-control form-control-sm"  :id="'comment_'+product.id" v-model="product.comment" :name="'comment' + index">
                </td>
              </tr>
              </tbody>
            </table>
            <a-skeleton active :loading="loading"></a-skeleton>
            <a-button class="btn btn-success waves-effect waves-light mr-2" :loading="updateRequisition" @click.prevent="update"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Update For Transfer</a-button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import $ from 'jquery'
import moment from 'moment'
import requisitionHeaderDetail from '@/views/requisition/partial/requisition_header_detail'
import requisitionHeaderStatus from '@/views/requisition/partial/requisition_header_status'
import requisitionHubDetail from '@/views/requisition/partial/requisition_hub_detail'

export default {
  name: 'update',
  components: { requisitionHeaderDetail, requisitionHeaderStatus, requisitionHubDetail },
  data() {
    return {
      products: [],
      product_ids: [],
      requisition_items: [],
      requisition: {},
      validation_errors: {},
      loader: false,
      loading: false,
      disabled: false,
      updateRequisition: false,
      current: 0,
      spinning: false,
      delayTime: 500,
    }
  },
  mounted() {
    this.onUpdate()
  },
  methods: {
    customDate(date) {
      return moment(date).format('LL')
    },
    onUpdate() {
      const requisitionId = this.$route.params.requisition_id
      this.loading = true
      apiClient.get('api/admin/hub/transfer-requisition/edit/' + requisitionId)
        .then(response => {
          this.loading = false
          const data = response.data
          this.products = data.products
          this.product_ids = data.product_ids
          this.requisition = data.requisition
          this.current = data.max_item_requisition_status
        })
        .catch(error => {
          console.log(error)
        })
    },
    acceptQuantity($event, index) {
      const acceptedAmount = $event
      const amount = this.products[index].amount
      if (parseInt(acceptedAmount) > parseInt(amount)) {
        this.$notification.error({
          message: 'Total accept quantity is crossing request quantity',
        })
        this.products[index].accepted_amount = ''
      }

      const availableQuantity = this.products[index].available_quantity || 0
      if (parseInt(acceptedAmount) > parseInt(availableQuantity)) {
        this.$notification.error({
          message: 'Total accept quantity is crossing available quantity',
        })
        this.products[index].accepted_amount = ''
      }
    },
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          const requisitionArray = []
          this.product_ids.forEach(id => {
            const amountDiv = '#accepted_amount_' + id
            const acceptedAmount = $(amountDiv).val()

            const commentDiv = '#comment_' + id
            const comment = $(commentDiv).val()
            if (acceptedAmount) {
              const requisition = {
                product_id: id,
                accepted_amount: acceptedAmount,
                comment: comment,
              }
              requisitionArray.push(requisition)
            }
          })
          this.updateRequisition = true
          const formData = new FormData()
          formData.append('requisition_items', JSON.stringify(requisitionArray))
          const requisitionId = this.$route.params.requisition_id
          apiClient.post('api/admin/hub/transfer-requisition/update/' + requisitionId, formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } })
            .then(response => {
              if (!response.data.error) {
                this.$notification.success({
                  message: response.data.message,
                })
                this.updateRequisition = false
                this.$router.push({ name: 'adminHubTransferRequisitionHistory' })
              } else {
                this.updateRequisition = false
                this.$notification.error({
                  message: response.data.message,
                })
              }
            }).catch(error => {
              this.updateRequisition = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style scoped>
table {
  position: relative;
}

th {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
}
</style>
